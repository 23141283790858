import {formatCardNumberToInterface} from "../../utilities/formatCard/formatCardNumber";
import {formatCardMonthToInterface} from "../../utilities/formatCard/formatCardMonth";

let inputs = Array.from(document.querySelectorAll('.card-date'));

if (inputs.length > 0) {
    for (let $input of inputs) {
        replaceCardDateSymbols($input);
    }
}

export function replaceCardDateSymbols($input){
    let $face = document.querySelector('.card-date-front')
    let $back = document.querySelector('.card-date-back')

    const regex = /[^0-9]/ig;

    $face.addEventListener('change', replace);
    $face.addEventListener('input', replace);
    function replace() {
       // console.log('REPLACE')
        let formatedValue = $face.value.replaceAll(regex, '');
        console.log(formatedValue[0])
        if (((formatedValue[0] ?? 0).toString() + (formatedValue[1] ?? 0).toString()) > 12){
            console.log('DATE SHIT')
            setInterface();
            return;
        }
        if (formatedValue.length > 4)
        {
            setInterface();
            return;
        }
        $back.value = formatedValue;
        setInterface();
    }

    $back.addEventListener('change', setInterface);
    $back.addEventListener('input', setInterface);

    function setInterface() {
        let fprmatedValue =  formatCardMonthToInterface($back.value);
        //console.log('setInterfaceCard')
        //console.log(fprmatedValue)
        $face.value = fprmatedValue;
    }
}