let $specialists = document.querySelectorAll('.blog__specialists-container');


if ($specialists.length > 2){
    let $prevBtn = document.querySelector('.blog__specialists-head-nav-left');
    let $nextBtn = document.querySelector('.blog__specialists-head-nav-right');
    let currentIndex = 0;

    $nextBtn.classList.add('blog__specialists-head-nav-btn--active');

    $prevBtn.addEventListener('click', ()=>{
        if (currentIndex > 0)
        {
            --currentIndex;
            clearActiveSpecialists();
            console.log()
            $specialists[currentIndex].classList.add('blog__specialists-container--current');
            if ($specialists[currentIndex + 1] != null){
                $specialists[currentIndex + 1].classList.add('blog__specialists-container--current');
            }
        }

        checkBtns()
    })

    $nextBtn.addEventListener('click', ()=>{
        console.log(($specialists.length - 1));
        console.log(currentIndex);

        if (currentIndex < ($specialists.length - 1))
        {
            ++currentIndex;
            clearActiveSpecialists();
            $specialists[currentIndex].classList.add('blog__specialists-container--current');
            if ($specialists[currentIndex + 1] != null){
                $specialists[currentIndex + 1].classList.add('blog__specialists-container--current');
            }
        }

        checkBtns()
    })


    function checkBtns(){

        if (currentIndex > 0)
        {
            $prevBtn.classList.add('blog__specialists-head-nav-btn--active');
        }
        else {
            $prevBtn.classList.remove('blog__specialists-head-nav-btn--active');
        }

        if (currentIndex < ($specialists.length - 1))
        {
            $nextBtn.classList.add('blog__specialists-head-nav-btn--active');
        }
        else {
            $nextBtn.classList.remove('blog__specialists-head-nav-btn--active');
        }
    }

    function clearActiveSpecialists(){
        $specialists.forEach($specialist=>{
            $specialist.classList.remove('blog__specialists-container--current')
        })
    }

}
