import Swiper from 'swiper/bundle';

if (document.getElementsByClassName('swiper-methodics')[0])
{
    let swiper_methodics = new Swiper('.swiper-methodics', {
        // Optional parameters
        //loop: true,
        speed: 400,
        spaceBetween: 16,
        autoHeight: true,
        grabCursor:true,
        slidesPerView: 'auto',
        centeredSlides: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 3,
                spaceBetween: 40
            }
        },

        // If we need pagination
        pagination: {
            el: '.swiper-methodics-pagination',
            type: 'bullets',
            clickable: true,
           // dynamicBullets: true,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-methodics-button-next',
            prevEl: '.swiper-methodics-button-prev',
            enabled: true
        },


    });
}

if (document.getElementsByClassName('swiper-diplom')[0])
{
    let swiper_diplom = new Swiper('.swiper-diplom', {
        // Optional parameters
        //loop: true,
        speed: 400,
        spaceBetween: 12,
        autoHeight: true,
        grabCursor:true,
        slidesPerView: 2,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 4,
                spaceBetween: 20
            }
        },
    });
}



if (document.getElementsByClassName('swiper-reviews')[0])
{
    const swiper_reviews = new Swiper('.swiper-reviews', {
        // Optional parameters
        //loop: true,
        speed: 400,
        spaceBetween: 16,
        autoHeight: true,
        grabCursor:true,
        slidesPerView: 'auto',
        centeredSlides: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 2,
                spaceBetween: 40,
            }
        },

        // If we need pagination
        pagination: {
            el: '.swiper-reviews-pagination',
            type: 'bullets',
            clickable: true,
            //dynamicBullets: true,

        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-reviews-button-next',
            prevEl: '.swiper-reviews-button-prev',
            enabled: true
        },
    });
}

if (document.getElementsByClassName('swiper-landing-review')[0])
{
    const swiper_reviews = new Swiper('.swiper-landing-review', {
        // Optional parameters
        //loop: true,
        speed: 400,
        spaceBetween: 16,
        autoHeight: true,
        grabCursor:true,
        slidesPerView: 'auto',
        centeredSlides: true,

    });
}


if (document.getElementsByClassName('swiper-helplist')[0])
{
    const swiper_helplist = new Swiper('.swiper-helplist', {
        // Optional parameters
        speed: 400,
        spaceBetween: 16,
        autoHeight: true,
        grabCursor:true,
        slidesPerView: 'auto',
        centeredSlides: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 2,
                spaceBetween: 40,
            }
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-helplist-button-next',
            prevEl: '.swiper-helplist-button-prev',
            enabled: true
        },
    });

    let $containerHelplist = document.querySelector('.container-helplist');
    let $cloneSpecialistItem = $containerHelplist.querySelector('#template-helplist-item');
    let $contentWrapper = $containerHelplist.querySelector('.swiper-wrapper');

    document.addEventListener('container-helplist-change', e=>{

        console.log('container-helplist-change');
        let specialistlist =e.detail.specialists;
        console.log(specialistlist)
        swiper_helplist.removeAllSlides();

        if (specialistlist)
        {
            specialistlist.forEach(specialist=>{
                const $clone = document.importNode($cloneSpecialistItem.content, true);

                $clone.querySelector('.tiny-carousel__container-item-title-profile-info-title').innerText = specialist['name_short']
                $clone.querySelector('.tiny-carousel__container-item-title-profile-info-experience span.focus').innerText = ' ' + specialist['experience'] + ' лет'
                let $container = $clone.querySelector('.container-helplist__item');
                let $directionsList = $clone.querySelector('.specialist-main__info-tag-container');
                let $image = $clone.querySelector('.tiny-carousel__container-item-title-profile-img');
                let $link = $clone.querySelector('.container-helplist__btn');

                $container.setAttribute('id', 'helplist-crm-id-' + specialist['denta_id'])
                $link.setAttribute('href', '/specialist/'+ specialist['id']+'/order')
                $image.innerHTML = '<img src="https://sense-life.ru/storage/specialists/'+specialist['avatar']['id'] +'/'+specialist['avatar']['file_name'] +'" alt="'+specialist['avatar']['file_name'] +'">';

                let dirrectionList = ''
                specialist['directions'].forEach(direction=>{
                    dirrectionList += '<div class="specialist-main__info-tag">'+direction['name']+'</div>'
                });
                $directionsList.innerHTML = dirrectionList;

                swiper_helplist.appendSlide($clone);
            })
        }

        getHelplistLastDates();
        $contentWrapper.classList.remove('preload')
        $contentWrapper.classList.remove('preload--gray')
    })

    function getHelplistLastDates() {
        let url = '/specialists/sync-last-date'
        axios.get(url)
            .then((response) => {
                console.log('RESP')
                console.log(response.data);

                console.log(document.querySelector('.container-helplist__item'))

                for (const specialist_crm_id in response.data) {
                    let $specialistContainer = document.querySelector('.container-helplist__item#helplist-crm-id-'+specialist_crm_id);


                    if ($specialistContainer)
                    {
                        let $specialistDateBlock = $specialistContainer.querySelector('.container-helplist__item-time'); //console.log('SET FIRST DATE')

                        if (response.data[specialist_crm_id] == null) {
                            $specialistDateBlock.innerText = 'не найдено';
                        } else {
                            $specialistDateBlock.innerText = response.data[specialist_crm_id][0]['formated'];
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


}

if (document.getElementsByClassName('swiper-intro')[0])
{
    const swiper_intro = new Swiper('.swiper-intro', {
        // Optional parameters
        //loop: true,
        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 10,
        autoHeight: true,
        grabCursor:true,
        updateOnWindowResize: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 4,
                spaceBetween: 30
            }
        }

    });
}
if (document.getElementsByClassName('swiper-social-services')[0])
{
    const swiper_social_services = new Swiper('.swiper-social-services', {
        // Optional parameters

        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 10,
        autoHeight: true,
        grabCursor:true,
        updateOnWindowResize: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 4,
                spaceBetween: 0
            }
        }

    });
}

if (document.getElementsByClassName('swiper-services')[0])
{
    const swiper_services = new Swiper('.swiper-services', {
        // Optional parameters
        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 10,
        grabCursor:true,
        centeredSlides: true,
        updateOnWindowResize: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 3,
                spaceBetween: 30
            }
        }

    });
}





let $mwBtn = document.querySelector('.map-wrapper-btn')
let $mwContainer = document.querySelector('.map-wrapper-container')

if ($mwBtn && $mwContainer){
    let switcherOn = false;
    $mwBtn.addEventListener('click', e=>{
        wrapMContainer()
    })

    function wrapMContainer(){
        if (switcherOn)
        {
            switcherOn = false
            $mwContainer.style.display = 'none'
            $mwBtn.classList.remove('contacts__footer-head--reverse');
        }
        else {
            switcherOn = true
            $mwContainer.style.display = 'grid'
            $mwBtn.classList.add('contacts__footer-head--reverse');
        }
    }

}

if (document.getElementsByClassName('swiper-gallery')[0])
{
    let swiper_gallery = new Swiper('.swiper-gallery', {
        // Optional parameters
        //loop: true,
        speed: 400,
        spaceBetween: 24,
        autoHeight: true,
        grabCursor:true,
        slidesPerView: 'auto',
        centeredSlides: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 'auto',
                spaceBetween: 24
            }
        },


        // Navigation arrows
        navigation: {
            nextEl: '.swiper-gallery-button-next',
            prevEl: '.swiper-gallery-button-prev',
            enabled: true
        },


    });
}
if (document.getElementsByClassName('swiper-our-team')[0])
{
    let swiper_team = new Swiper('.swiper-our-team', {
        // Optional parameters
        //loop: true,
        speed: 400,
        spaceBetween: 24,
        autoHeight: true,
        grabCursor:true,
        slidesPerView: 1,
        //centeredSlides: true,
        width: 222,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                spaceBetween: 24,
                slidesPerView: 4,
            }
        },

        // If we need pagination
        pagination: {
            el: '.swiper-methodics-pagination',
            type: 'bullets',
            clickable: true,
            //dynamicBullets: true,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-methodics-button-next',
            prevEl: '.swiper-methodics-button-prev',
            enabled: true
        },


    });
}

if (document.getElementsByClassName('swiper-certificate')[0])
{
    let swiper_certificate= new Swiper('.swiper-certificate', {
        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 20,
        autoHeight: true,
        grabCursor:true,
        updateOnWindowResize: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 4,
                spaceBetween: 20
            }
        }

    });
}



//swiper.slideNext();
