let $containerHelplist = document.querySelector('.container-helplist');

if ($containerHelplist) {
    let $listCategory = $containerHelplist.querySelector('.container-helplist__categories');
    let $listCategoryItems = $listCategory.querySelectorAll('.tips');

    let $cloneSpecialistItem = $containerHelplist.querySelector('#template-helplist-item');
    let $contentWrapper = $containerHelplist.querySelector('.swiper-wrapper');

    $listCategoryItems.forEach($listCategoryItem => {
        $listCategoryItem.addEventListener('click', e => {
            console.log('click', $listCategoryItem.dataset.id);
            $contentWrapper.classList.add('preload')
            $contentWrapper.classList.add('preload--gray')
            getHelplistSpecialistByCategory($listCategoryItem.dataset.id);
            changeTip($listCategoryItem)
        })
    })
    $contentWrapper.classList.add('preload')
    $contentWrapper.classList.add('preload--gray')
    getHelplistSpecialistByCategory(null);

    function changeTip($listCategoryItem){
        $listCategoryItems.forEach($listCategoryItem => {
            $listCategoryItem.classList.remove('tips--active')
        })

        $listCategoryItem.classList.add('tips--active')
    }
}



export function getHelplistSpecialistByCategory(categoryId) {
    let filterData = null

    if (categoryId){
        filterData = {
            'directions': {'value': categoryId}
        }
    }

    let url = '/specialists/get';
    axios.get(url, {
        params: filterData
    }).then(response => {
        console.log(response.data.data);
        let event = new CustomEvent('container-helplist-change', {
            'detail': {
                "specialists": response.data.data
            }
        });
        document.dispatchEvent(event);
    }).catch(error => {
        console.log(error);
    });

}


