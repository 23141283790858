import {refreshModalTips} from "../modal/modal-tip";
let $modalTip = document.querySelector('.modal-tip');
let $specialistTagLoaderContainers = document.querySelectorAll('.specialist-tag-loader');

$specialistTagLoaderContainers.forEach($specialistTagLoaderContainer => {

    let $specialistId = $specialistTagLoaderContainer.dataset.specialist

    let url = location.origin + '/specialist/' + $specialistId + '/tags/'
    axios.get(url)
        .then((response) => {
            console.log(response.data.tags)

            formateTags($specialistTagLoaderContainer,response.data.tags);

            $specialistTagLoaderContainer.classList.remove('preload');

            refreshModalTips($modalTip);
        })
        .catch((error) => {
            console.log(error)
        });
})

function formateTags($container, tags, mobile = false) {

    let tagFragment = document.createDocumentFragment();

    tags.forEach(tag => {
        let tagElement

        if (tag.link != null) {
            tagElement = document.createElement('a');
            tagElement.setAttribute('href', '/s-chem-pomogaem/' + tag.link);
            tagElement.classList.add('specialist-new__profile-tag', 'specialist-new__profile-tag--link');
        } else {
            tagElement = document.createElement('div');
            tagElement.classList.add('specialist-new__profile-tag');
        }

        if (tag.tip != null){
            let tagTipElement = document.createElement('div');
            tagTipElement.setAttribute('data-content', tag.id);
            tagTipElement.classList.add('specialist-new__profile-tag--tip-icon', 'modal-tip-trigger');

            tagElement.classList.add('specialist-new__profile-tag--tip');
            tagElement.appendChild(tagTipElement);

            tagElement.innerHTML = tag.name + '<div data-content="'+tag.tip+'"\n' +
                '                                                     class="specialist-new__profile-tag--tip-icon modal-tip-trigger">\n' +
                '                                                </div>'
        }
        else {
            tagElement.textContent = tag.name;
        }

        tagElement.setAttribute('data-id', tag.id);

        tagFragment.appendChild(tagElement);

    })

    $container.appendChild(tagFragment);
}
