let $specialistShareBtns = document.querySelectorAll('.specialist-share-btn')

let $shareContainer = document.querySelector('.ya-share2 ');

if ($shareContainer){

    $specialistShareBtns.forEach($btn =>{
        let specialistId = $btn.dataset.specialist;

        let vkHref = 'https://oauth.vk.com/authorize?client_id=-1&redirect_uri=https%3A%2F%2Fvk.com%2Fshare.php%3Furl%3Dhttps%253A%252F%252Fsense-life.ru%252Fspecialist%252F'+specialistId+'%26utm_source%3Dshare2&display=widget';
        let tgHref = 'https://t.me/share/url?url=https%3A%2F%2Fsense-life.ru%2Fspecialist%2F'+specialistId+'&utm_source=share2';
        let wpHref = 'https://api.whatsapp.com/send?text=https%3A%2F%2Fsense-life.ru%2Fspecialist%2F'+specialistId+'&utm_source=share2';

        $btn.addEventListener('click', e=>{
            let $vkLink = $shareContainer.querySelector('.ya-share2__item_service_vkontakte a');
            let $tgLink = $shareContainer.querySelector('.ya-share2__item_service_telegram a');
            let $wpLink = $shareContainer.querySelector('.ya-share2__item_service_whatsapp a');

            $vkLink.href = vkHref;
            $tgLink.href = tgHref;
            $wpLink.href = wpHref;

            modals.open('share');
        })

    })
}

