let $promoContainer = document.querySelector('.input-promo');

if ($promoContainer) {
    let $promoInput = $promoContainer.querySelector('.input-promo-form');
    let $promoBtn = $promoContainer.querySelector('.input-promo-btn');
    let $promoMessage = $promoContainer.querySelector('.input-promo-message');

    let $successPromoTemplate = document.querySelector('#active-promocode-template');
    let $successPromoContainer = document.querySelector('.promo-success-container');


    let activeClass = 'input-promo--active';
    let successClass = 'input-promo--success';
    let errorClass = 'input-promo--error';
    let promoText = '';
    let basePrice = $promoContainer.dataset.price;
    let salePrice = basePrice;
    let promoLimit = 1;

    $promoInput.addEventListener('input', () => {

        if ($promoInput.value.length < 7) {
            promoText = $promoInput.value;
        }

        if ($promoInput.value.length >= 1) {
            $promoContainer.classList.add(activeClass);
        } else {
            $promoContainer.classList.remove(activeClass);
        }

        $promoInput.value = promoText

        console.log(promoText);
    });

    $promoBtn.addEventListener('click', () => {
        checkPromocode();
    })

    function checkPromocode() {
        if (promoText.length < 1)
            return;

        axios.post('/promocode/check', {
            'promocode': promoText
        }).then((response) => {
            console.log(response.data)
            console.log(basePrice)
            console.log(promoLimit)

            if (response.data != null && response.data != '' && basePrice > 0 && promoLimit > 0) {
                let discountPercent = response.data['sale'];
                let discountAmount = (basePrice * discountPercent) / 100;
                salePrice = basePrice - discountAmount;

                let customEvent = new CustomEvent('change-order-price', {'detail': {"newPrice": salePrice}})
                document.dispatchEvent(customEvent);

                setTesultMessage();
                addSuccessPromocode(discountAmount);

            } else {
                setTesultMessage(false);
            }
        }).catch((error) => {
            setTesultMessage(false);
            console.log(error)
        });

    }

    function setTesultMessage(isSuccess = true) {
        if (isSuccess) {
            $promoContainer.classList.remove(errorClass);
            $promoContainer.classList.add(successClass);
            $promoMessage.innerText = 'Промокод ' + promoText + ' успешно применён';
        } else {
            $promoContainer.classList.remove(successClass);
            $promoContainer.classList.add(errorClass);
            $promoMessage.innerText = 'Ошибка применения промокода';
        }
    }
    function clearResultMesssage(){
        $promoContainer.classList.remove(successClass);
        $promoContainer.classList.remove(errorClass);
        $promoMessage.innerText = '';
    }

    function addSuccessPromocode(discountAmount){

        if ($successPromoTemplate && $successPromoContainer)
        {
            const $clone = document.importNode($successPromoTemplate.content, true);
            const $cloneMessage = $clone.querySelector('.promocode-success-item-text');
            const $cloneSale = $clone.querySelector('.promocode-success-item-sale');
            const $cloneBtn = $clone.querySelector('.promocode-success-item-btn');
            const $cloneContainer = $clone.querySelector('.promocode-success-item');

            $cloneContainer.dataset.item = promoText;
            $cloneBtn.dataset.item = promoText;
            $cloneMessage.innerText = 'Промокод ' + promoText;
            $cloneSale.innerHTML = '−'+discountAmount+'₽';

            $cloneBtn.addEventListener('click', ()=>{
                console.log('REMOVE CLICK');
                console.log($cloneContainer);
                $cloneContainer.remove();
                clearResultMesssage();
                let customEvent = new CustomEvent('change-order-price', {'detail': {"newPrice": basePrice}})
                document.dispatchEvent(customEvent);
                ++promoLimit;
            })

            $successPromoContainer.appendChild($clone);
            --promoLimit;
        }

    }

}
