let $validateContainers = document.querySelectorAll('.input-validator');

let inputErrorClass = 'input-validator--error';

if ($validateContainers) {
    $validateContainers.forEach($validateContainer => {
        let $requireCollumns = $validateContainer.querySelectorAll('.input-validator-required');
        $requireCollumns.forEach($requireCollumn => {
            $requireCollumn.onblur = function () {
                console.log('blur');
                markValidation($requireCollumn);

            };

            $requireCollumn.onfocus = function () {
                if ($requireCollumn.classList.contains(inputErrorClass)) {
                    $requireCollumn.classList.remove(inputErrorClass);
                }
            };
        })


    })
}

function markValidation($requireCollumn) {

    if ($requireCollumn.value.length <= 0) {
        $requireCollumn.classList.add(inputErrorClass);
    }
    if ($requireCollumn.dataset.validation == 'email') {
        if (!$requireCollumn.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            $requireCollumn.classList.add(inputErrorClass);
        }
    }
    if ($requireCollumn.dataset.validation == 'phone') {
        if ($requireCollumn.value.length != 16) {
            $requireCollumn.classList.add(inputErrorClass);
        }
    }


}

export function validationCheck($validateContainer) {

    let $requireCollumns = $validateContainer.querySelectorAll('.input-validator-required');

    let successsValidation = true;
    $requireCollumns.forEach($requireCollumn => {
        markValidation($requireCollumn);

        if ($requireCollumn.value.length <= 0) {
            successsValidation = false;
        }
        if ($requireCollumn.dataset.validation == 'email') {
            if (!$requireCollumn.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                successsValidation = false;
            }
        }
        if ($requireCollumn.dataset.validation == 'phone') {
            if ($requireCollumn.value.length != 16) {
                successsValidation = false;
            }
        }

    })

    return successsValidation

}
