import {getCookie} from "../cookie/cookie";

let firstStart = true
let firstclick = false;
let $calendar = document.querySelector('.calendar')

function Calendar(name, year, month) {
    let $containerBody = document.createElement('tbody');
    $containerBody.classList.add('calendar__tbody')
    let $containerHead = document.createElement('thead');
    $containerHead.classList.add('calendar__thead')
    $calendar.appendChild($containerHead);
    $calendar.appendChild($containerBody);

    let curDate = new Date()
    let backAble = true;

    if (curDate.getFullYear() == year &&  curDate.getMonth() == month){
            $calendar.classList.add('calendar--start');
        backAble = false;
    }
    else {
        $calendar.classList.remove('calendar--start');
    }

    let curMonth = month == 12 ? 1 : month + 1
    curMonth = curMonth == 0 ? 12 : curMonth;

    var Dlast = new Date(year, month + 1, 0).getDate(),
        D = new Date(year, month, Dlast),
        DNlast = new Date(D.getFullYear(), D.getMonth(), Dlast).getDay(),
        DNfirst = new Date(D.getFullYear(), D.getMonth(), 1).getDay(),
        calendar = '<tr>',
        month = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    if (DNfirst != 0) {
        for (var i = 1; i < DNfirst; i++) calendar += '<td>';
    } else {
        for (var i = 0; i < 6; i++) calendar += '<td>';
    }
    for (var i = 1; i <= Dlast; i++) {
        let calendarDay = i < 10 ? ('0' + i) : i
        let calendarMonth = curMonth < 10 ? ('0' + curMonth) : curMonth
        if (i == new Date().getDate() && D.getFullYear() == new Date().getFullYear() && D.getMonth() == new Date().getMonth()) {
            calendar += '<td data-times=""  data-date="' + D.getFullYear() + '-' + calendarMonth + '-' + calendarDay + '" data-year="' + D.getFullYear() + '" data-month="' + calendarMonth + '" data-day="' + calendarDay + '" class="container today disable">' + i;
        } else if ((i <= new Date().getDate() && D.getFullYear() <= new Date().getFullYear() && D.getMonth() <= new Date().getMonth()) || (D.getMonth() < new Date().getMonth() && D.getFullYear() <= new Date().getFullYear())) {
            calendar += '<td data-times=""  data-date="' + D.getFullYear() + '-' + calendarMonth + '-' + calendarDay + '" data-year="' + D.getFullYear() + '" data-month="' + calendarMonth + '" data-day="' + calendarDay + '" class="container disable">' + i;
        } else {
            calendar += '<td data-times=""  data-date="' + D.getFullYear() + '-' + calendarMonth + '-' + calendarDay + '"  data-year="' + D.getFullYear() + '" data-month="' + calendarMonth + '" data-day="' + calendarDay + '" class="container disable">' + i;
        }
        if (new Date(D.getFullYear(), D.getMonth(), i).getDay() == 0) {
            calendar += '<tr>';
        }
    }

    for (var i = DNlast; i < 7; i++) calendar += '<td>&nbsp;';


    $calendar.querySelector('.calendar__thead').innerHTML = ' <tr>\n' +
    '                                <td class="calendar__month" style="text-align: start" colspan="3">\n' +
    '                                <td>\n' +
    '                                <td class="calendar__month" style="text-align: end" colspan="3">\n' +
    '                            </tr>\n' +
    '                            <tr>\n' +
    '                                <td>Пн\n' +
    '                                <td>Вт\n' +
    '                                <td>Ср\n' +
    '                                <td>Чт\n' +
    '                                <td>Пт\n' +
    '                                <td>Сб\n' +
    '                                <td>Вс\n' +
    '                            </tr>'


    $calendar.querySelector('.calendar__tbody').innerHTML = calendar;
    $calendar.querySelector('.calendar__thead td:nth-child(1)').innerHTML = month[D.getMonth()];
    $calendar.querySelector('.calendar__thead td:nth-child(1)').dataset.month = D.getMonth();
    $calendar.querySelector('.calendar__thead td:nth-child(1)').dataset.year = D.getFullYear();


    $calendar.querySelector('.calendar__thead td:nth-child(3)').innerHTML = month[(D.getMonth() + 1) == 12 ? 0 : D.getMonth() + 1];

    if ($calendar.querySelectorAll('.calendar__tbody tr').length < 6) {  // чтобы при перелистывании месяцев не "подпрыгивала" вся страница, добавляется ряд пустых клеток. Итог: всегда 6 строк для цифр
        $calendar.querySelector('.calendar__tbody').innerHTML += '<tr><td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;<td>&nbsp;';
    }

    let $days = document.querySelectorAll('.' + name + ' tbody td.container')
    $days.forEach($day => {

        $day.addEventListener('click', e => {
            let $currentDay = e.currentTarget;

            if (refreshCalendar($calendar, $days, $currentDay)){
                let times = JSON.parse($currentDay.dataset.times) || [];

                renderTime(times)

                refreshBtns(false)
            }


            //getTimes($calendar.dataset.day)
        })
    })

    if (backAble){
        // переключатель минус месяц
        document.querySelector('.calendar thead tr:nth-child(1) td:nth-child(1)').onclick = function () {
            let month = parseFloat(document.querySelector('.calendar thead td:nth-child(1)').dataset.month) - 1
            let year = document.querySelector('.calendar thead td:nth-child(1)').dataset.year;
            Calendar("calendar", year , month);

            let Dlast = new Date(year, month + 1, 0).getDate(),
                D = new Date(year, month, Dlast)
            getMonthDates(month, D.getFullYear());
        }
    }

// переключатель плюс месяц
    document.querySelector('.calendar thead tr:nth-child(1) td:nth-child(3)').onclick = function () {
        let month = parseFloat(document.querySelector('.calendar thead td:nth-child(1)').dataset.month) + 1
        let year = document.querySelector('.calendar thead td:nth-child(1)').dataset.year;
        Calendar("calendar", year, month);


        let Dlast = new Date(year, month + 1, 0).getDate(),
            D = new Date(year, month, Dlast)
        getMonthDates(month, D.getFullYear());
    }
}

function refreshCalendar($calendar, $days, $currentDay) {
    if ($currentDay.classList.contains('disable')) {
        return false
    }

    $days.forEach($day => {
        if ($day.classList.contains('current')) {
            $day.classList.remove('current');
        }
        if ($day === $currentDay) {
            $day.classList.add('current')
            $calendar.dataset.day = $currentDay.dataset.year + '-' + $currentDay.dataset.month + '-' + $currentDay.dataset.day
        }

    })

    return true
}

async function getAvailableDays(spec_id) {
    let timezone = getCookie('timezone');
    let timezoneParam = ''

    if (timezone)
    {
        timezoneParam = '?timezone=' + timezone
    }

    const url = '/specialist/' + spec_id + '/free-dates' + timezoneParam
    console.log(url)
    const response = await axios.get(url);

    if  (response.status !== 200) {
        return [];
    }

    return response.data;
}

function CalendarTime() {
    let $timeContainer = document.querySelector('.calendar__times');
    let $timeItems = $timeContainer.querySelectorAll('.calendar__times-item')

    setTimesListener($timeItems)
}

function regenerateTimeAction() {
    let $timeContainer = document.querySelector('.calendar__times');
    let $timeItems = $timeContainer.querySelectorAll('.calendar__times-item')

    setTimesListener($timeItems)
}

function setTimesListener($times) {

    $times.forEach($time => {
        $time.addEventListener('click', e => {
            refreshTime($times, e.currentTarget)
            $calendar.dataset.time = e.currentTarget.innerText;
            $calendar.dataset.slot = e.currentTarget.dataset.slot_id;
            refreshBtns()
        })
    })
}

function getTimes(day) {
    let $timeContainer = document.querySelector('.calendar__times');
    $timeContainer.innerHTML = '';

    let url = '/specialists/free-time?date=' + day
    axios.get(url)
        .then((response) => {
            renderTime(response.data)
        })
        .catch((error) => {
            console.log(error)
        });
}

function renderTime($times) {
    let $timeContainer = document.querySelector('.calendar__times');
    let $innerTimeContent = ''

    $times.forEach($time => {
        $innerTimeContent += '<div class="calendar__times-item" data-slot_id="' + $time.slot_id + '" data-time="' + $time.time +'">' + $time.time + '</div>'
    })

    $timeContainer.innerHTML = $innerTimeContent;
    regenerateTimeAction();
}

function refreshTime($times, $curTime) {
    $times.forEach($time => {
        if ($time.classList.contains('calendar__times-item--active')) {
            $time.classList.remove('calendar__times-item--active');
        }
        if ($time === $curTime) {
            $time.classList.add('calendar__times-item--active')
        }
    })
}

function getMonthDates(month, year) {
    let curMonth = month == 12 ? 1 : month + 1
    curMonth = curMonth == 0 ? 12 : curMonth;

    let timezone = getCookie('timezone');
    let timezoneParam = ''

    if (timezone)
    {
        timezoneParam = '&timezone=' + timezone
    }
    let url = '/specialists/free-date?month=' + curMonth +'&year=' + year + timezoneParam;
    if($calendar.dataset.specialist)
        url += '&specialist=' + $calendar.dataset.specialist

    axios.get(url)
        .then((response) => {
            refreshBtns(false)
            if (response.data != null) {
                fillFreeDates(response.data);
            }

        })
        .catch((error) => {
            console.log(error)
        });
}

function fillFreeDates(dates) {
    let $containers = $calendar.querySelectorAll('.container')
    $containers.forEach($container=>{
        $container.classList.add('disable')
    })

    const keys =  Object.keys(dates)

    for (let i = 0; i < keys.length; i++) {
        const $date = keys[i]
        const $times = dates[keys[i]]



        let $curDate = $calendar.querySelector('.container[data-date="' + $date + '"]')

        if ($curDate) {
            $curDate.dataset.times = JSON.stringify($times);
            $curDate.classList.remove('disable');
        }
}
}

function refreshBtns(toActive = true){
    let $calendarBtn = document.querySelector('.calendar-filter-btn');
    let $calendarOrderBtn = document.querySelector('.calendar-filter-order-btn');

    if (toActive == false)
    {
        if ($calendarBtn)
        $calendarBtn.classList.add('btn-blue--disable')
        if ($calendarOrderBtn)
        $calendarOrderBtn.classList.add('btn-blue--disable')
    }
    else{
        if ($calendarBtn)
            $calendarBtn.classList.remove('btn-blue--disable')
        if ($calendarOrderBtn)
            $calendarOrderBtn.classList.remove('btn-blue--disable')
    }

}

if (document.querySelector('.calendar')) {
    Calendar("calendar", new Date().getFullYear(), new Date().getMonth());
    CalendarTime();

    let $calendarBtn = document.querySelector('.calendar-filter-btn');
    if($calendarBtn){
        $calendarBtn.addEventListener('click', e=>{
            if (!$calendarBtn.classList.contains('btn-blue--disable'))
            {
                let event = new CustomEvent("refreshDate");
                document.dispatchEvent(event);
            }

        })
    }
    let $calendarOrderBtn = document.querySelector('.calendar-filter-order-btn');
    if($calendarOrderBtn){
        $calendarOrderBtn.addEventListener('click', e=>{

            if (!$calendarOrderBtn.classList.contains('btn-blue--disable'))
            {
                let event = new CustomEvent("specialist-order-custom-date");
                document.dispatchEvent(event);

                let event2 = new CustomEvent("order-date-change");
                document.dispatchEvent(event2);

                let event3 = new CustomEvent("more-date-calendar-accept");
                document.dispatchEvent(event3);
            }

        })
    }

    let month = parseFloat(document.querySelector('.calendar thead td:nth-child(1)').dataset.month);


    getMonthDates(month, new Date().getFullYear())

    document.addEventListener('timezone-changed', e=>{
        getMonthDates(month, new Date().getFullYear())
    })

    document.addEventListener("showCalendar", async (e) => { // (1)
        //let curSpecialist = document.querySelect/or('.more-date-btn').dataset.specialist;
        let startDate = document.querySelector('.calendar--start');
        let curTime = document.querySelector('.calendar .container.today');
        if (curTime && firstStart) {
            firstStart = false;
            const dates = await getAvailableDays(startDate.dataset.specialist)

            fillFreeDates(dates)

            setTimeout(function (){
                let $firstBtn = document.querySelector('.calendar .container:not(.disable)');
                $firstBtn.click();
            }, 1000)
        }
    });

    //let $calendar = document.querySelector('.calendar');
    //getTimes($calendar.dataset.day)




}

