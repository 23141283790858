import {formatCardNumberToInterface} from "../../utilities/formatCard/formatCardNumber";

let inputs = Array.from(document.querySelectorAll('.card-number'));

if (inputs.length > 0) {
    for (let $input of inputs) {
        replaceCardSymbols($input);
    }
}

export function replaceCardSymbols($input){
    let $face = document.querySelector('.card-number-front')
    let $back = document.querySelector('.card-number-back')

    const regex = /[^0-9]/ig;

    $face.addEventListener('change', replace);
    $face.addEventListener('input', replace);
    function replace() {
        //console.log('REPLACE')
        let formatedValue = $face.value.replaceAll(regex, '');
        if (formatedValue.length > 16)
        {
            setInterface();
            return;
        }
        $back.value = formatedValue;
        setInterface();
    }

    $back.addEventListener('change', setInterface);
    $back.addEventListener('input', setInterface);

    function setInterface() {
        let fprmatedValue =  formatCardNumberToInterface($back.value);
        //console.log('setInterfaceCard')
        //console.log(fprmatedValue)
        $face.value = fprmatedValue;
    }
}