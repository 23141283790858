let $frameItemsContainer = document.querySelector('.vertical-carousel');


if ($frameItemsContainer) {

    initCarousel();
}

function initCarousel() {
    console.log('startCarousel')
    let height = $frameItemsContainer.getBoundingClientRect().height;
    console.log(height)

    let delay = 2500;

    let $items = $frameItemsContainer.querySelectorAll('.vertical-carousel-item');
    let $containerWrapper = $frameItemsContainer.querySelector('.vertical-carousel-items');

    let itemsCount = $items.length;
    let counter = 1;


    console.log(counter, itemsCount, height);

    if (itemsCount < 2) {
        return
    }

    setInterval(function () {
        //const $clone = $frameItemsContainer.querySelector('.vertical-carousel-item:nth-child(' + counter + ')').cloneNode(true);

        //console.log('INTERVAL:',counter, itemsCount, $containerWrapper.style.transition)

        if (counter == itemsCount){
            counter = 0;
            $containerWrapper.style.transition = 'none';
            $containerWrapper.style.transform = 'translateY(' + (-height * counter) + 'px)';

            setTimeout(function (){
                counter++
                $containerWrapper.style.transition = 'all .3s linear';
                $containerWrapper.style.transform = 'translateY(' + (-height * counter) + 'px)';
            }, 200)

        }
        else {
            $containerWrapper.style.transition = 'all .3s linear';
            $containerWrapper.style.transform = 'translateY(' + (-height * counter) + 'px)';
            counter++
        }


    }, delay);
}