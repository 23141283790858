import {formatCardNumberToInterface} from "../../utilities/formatCard/formatCardNumber";
import {formatCardMonthToInterface} from "../../utilities/formatCard/formatCardMonth";

let inputs = Array.from(document.querySelectorAll('.card-cvv'));

if (inputs.length > 0) {
    for (let $input of inputs) {
        replaceCardDateSymbols($input);
    }
}

export function replaceCardDateSymbols($input){
    let $cvvInput = $input.querySelector('input')
    const regex = /[^0-9]/ig;

    $cvvInput.addEventListener('change', replace);
    $cvvInput.addEventListener('input', replace);
    function replace() {

        let formatedValue = $cvvInput.value.replaceAll(regex, '');
        if (formatedValue.length > 3)
        {
            $cvvInput.value = formatedValue.substring(0, 3);
            return;
        }

        $cvvInput.value = formatedValue;
    }

}